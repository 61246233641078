.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


#root {
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.float-right {
  float: right;
}

.click-text {
  cursor: pointer;
  text-decoration: underline;
}

.w-100 {
  width: 100%;
}

.py-3 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.fixed-bottom {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}

.bg-light {
  background-color: rgb(248, 249, 250);
}

.d-flex {
  display: flex !important;
}

.p-3 {
  padding: 1rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.h-100 {
  height: 100%;
}

.dropdown-border {
  border-radius: .28571429rem;
}

.workout-table-container {
  margin-right: 17rem !important;
}